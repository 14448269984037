<template>
  <div>
    <div class="sign-in-container">
      <div class="app-header">
        <img alt="logo" :src="logoPath"/>
      </div>
      <div
        v-if="externalAuth.enabled"
        class="sign-in-form"
      >
        <h5 class="title">{{ externalAuth.label }}</h5>
        <form>
          <div class="form-row">
            <b-button
              variant="primary"
              block
              @click.prevent="submitExternalAuth"
            >
              {{ $t('words.login') }}
            </b-button>
          </div>
        </form>
      </div>
      <div class="sign-in-form">
        <h5
          v-if="$config.forms.signin.title"
          class="title"
        >
          {{ $config.forms.signin.title[$i18n.locale] }}
        </h5>
        <h5
          v-else
          class="title"
        >
          {{ $t('signin.title', { org: $config.client.name }) }}
        </h5>
        <form>
          <div class="form-row">
            <input
              v-model="form.username"
              class="form-control"
              type="text"
              :placeholder="$t('words.username')"
              v-on:keydown.enter.prevent="submit"
            >
          </div>
          <div class="form-row">
            <div class="input-group flex-nowrap">
              <input
                v-model="form.password"
                class="form-control"
                :type="showPassword ? 'text' : 'password'"
                :placeholder="$t('words.password')"
                v-on:keydown.enter.prevent="submit"
              >
              <span
                v-if="!showPassword"
                class="input-group-text"
              >
                <b-icon
                  icon="eye-fill"
                  @click="showPassword = !showPassword"
                />
              </span>
              <span
                v-else
                class="input-group-text"
              >
                <b-icon
                  icon="eye-slash-fill"
                  @click="showPassword = !showPassword"
                />
              </span>
            </div>
          </div>
          <div class="form-row">
            <div v-if="error" class="form-row">
              <span class="form-errors">{{ error }}</span>
            </div>
          </div>
          <div class="form-row">
            <b-button
              :pressed="btnPressed"
              :disabled="(!username || !password)"
              @click.prevent="submit"
              variant="primary"
              block
            >
              {{ $t('words.login') }}
            </b-button>
          </div>
          <div
            v-if="$config.client.warning && $config.client.name && $config.client.email"
            class="form-row attention-message"
          >
            <img
              src="@/assets/attention_image.jpg"
              height="100"
            />
            <p>
              {{ $t('signin.warning', { org: $config.client.name }) }}
              <a :href="`mailto:${$config.client.email}`">{{ $config.client.email }}</a>
            </p>
          </div>
          <div class="form-row">
            <div class="btn-group-vertical">
              <router-link
                v-if="!$config.forms.signup.disabled"
                :to="{ name: 'SignUp' }"
                custom
              >
                {{ $t('signin.noaccount') }} ?
              </router-link>
              <router-link
                :to="{ name: 'ForgottenPassword' }"
                custom
              >
                {{ $t('signin.forgottenpassword') }} ?
              </router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
    <small class="footer">
      <p>{{ $t('footer') }} <a href="https://www.neogeo.fr/" target="_blank" rel="noopener">Neogeo-Technologies</a></p>
    </small>
  </div>
</template>

<script>

import {
  mapState,
  mapActions,
  mapMutations,
} from 'vuex';

// const EXTERNAL_LOGIN = process.env.VUE_APP_EXTERNAL_LOGIN;
// const EXTERNAL_LOGIN_URL = process.env.VUE_APP_EXTERNAL_LOGIN_URL;
// const EXTERNAL_LOGIN_LABEL = process.env.VUE_APP_EXTERNAL_LOGIN_LABEL

const signInActions = [
  'POST_SIGNIN'
];
const signInMutations = [
  'SET_LOGGED',
  'SET_NEXT',
  'SET_USERNAME',
  'SET_PASSWORD',
];

export default {
  name: 'SignIn',

  data() {
    return {
      externalAuth: {
        enabled: process.env.VUE_APP_EXTERNAL_LOGIN,
        url: process.env.VUE_APP_EXTERNAL_LOGIN_URL,
        label: process.env.VUE_APP_EXTERNAL_LOGIN_LABEL
      },

      showPassword: false,
      btnPressed: false,
      form: {
        username: null,
        password: null
      }
    };
  },

  computed: {
    ...mapState('sign-in', [
      'username',
      'password',
      'logged',
      'error',
      'next',
    ]),

    logoPath() {
      return require(process.env.VUE_APP_LOGO);
    }
  },

  watch: {
    form: {
      deep: true,
      handler(newValue) {
        this.SET_USERNAME(newValue.username);
        this.SET_PASSWORD(newValue.password);
      }
    },

    logged() {
      if (this.logged && this.next) {
        if (
          this.$config.authorizedRedirections.some(reg => {
            return reg.test(decodeURIComponent(this.next))
          })
        ) {
          window.location.href = this.next;
        } else {
          this.$router.push({ name: 'NotFound' });
        }
      }
    }
  },

  created() {
    this.SET_NEXT(this.$route.query.next || process.env.VUE_APP_NEXT_DEFAULT);
  },

  methods: {
    ...mapActions('sign-in', signInActions),
    ...mapMutations('sign-in', signInMutations),
    async submit() {
      this.btnPressed = true;
      await this.POST_SIGNIN();
    },
    submitExternalAuth() {
      window.location.href = this.externalAuth.url;
    }
  },
};
</script>

<style lang="less" scoped>
.sign-in-container {
  margin: 1rem auto auto;
  width: 480px;
  height: fit-content;

  .sign-in-form {
    margin: 5rem 1rem;

    h5.title {
      color: #373b3d;
    }

    form {
      margin: 2rem 0.25rem;

      .form-row {
        margin-bottom: 1.25em;

        .input-group {
          span {
            cursor: pointer;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            border-left: none
          }
        }
      }

      .row {
        .col-7 {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .checkbox {
            label {
              small {
                cursor: pointer !important;
                input {
                  cursor: pointer !important;
                }
              }
            }
          }
        }

        .col-5 {
          button {
            float: right;
          }
        }
      }

      .btn-group-vertical {
        margin-top: 2rem;

        a {
          text-decoration: none;
        }
      }
    }
  }
}

.attention-message {
  margin-bottom: 0 !important;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  img {
    margin-right: 1em;
  }
  p {
    color: red;
    text-align: justify;
    font-size: 0.8em;
  }
}

.form-errors {
  color: #EB0600 !important;
}

.form-success {
  color: #30C963 !important;
}

.footer {
  position: absolute;
  bottom: 0;
  font-size: small;
}

.footer a {
  text-decoration: none;
}
</style>
